exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-fact-sheet-js": () => import("./../../../src/pages/about/fact-sheet.js" /* webpackChunkName: "component---src-pages-about-fact-sheet-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-investment-criteria-js": () => import("./../../../src/pages/about/investment-criteria.js" /* webpackChunkName: "component---src-pages-about-investment-criteria-js" */),
  "component---src-pages-about-sustainable-finance-disclosures-js": () => import("./../../../src/pages/about/sustainable-finance-disclosures.js" /* webpackChunkName: "component---src-pages-about-sustainable-finance-disclosures-js" */),
  "component---src-pages-about-team-js": () => import("./../../../src/pages/about/team.js" /* webpackChunkName: "component---src-pages-about-team-js" */),
  "component---src-pages-complaints-js": () => import("./../../../src/pages/complaints.js" /* webpackChunkName: "component---src-pages-complaints-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-impact-index-js": () => import("./../../../src/pages/impact/index.js" /* webpackChunkName: "component---src-pages-impact-index-js" */),
  "component---src-pages-impact-meet-our-clients-js": () => import("./../../../src/pages/impact/meet-our-clients.js" /* webpackChunkName: "component---src-pages-impact-meet-our-clients-js" */),
  "component---src-pages-impact-social-reports-js": () => import("./../../../src/pages/impact/social-reports.js" /* webpackChunkName: "component---src-pages-impact-social-reports-js" */),
  "component---src-pages-in-the-media-js": () => import("./../../../src/pages/in-the-media.js" /* webpackChunkName: "component---src-pages-in-the-media-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-partners-index-js": () => import("./../../../src/pages/partners/index.js" /* webpackChunkName: "component---src-pages-partners-index-js" */),
  "component---src-pages-partners-investees-js": () => import("./../../../src/pages/partners/investees.js" /* webpackChunkName: "component---src-pages-partners-investees-js" */),
  "component---src-pages-partners-investors-js": () => import("./../../../src/pages/partners/investors.js" /* webpackChunkName: "component---src-pages-partners-investors-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-videos-index-js": () => import("./../../../src/pages/videos/index.js" /* webpackChunkName: "component---src-pages-videos-index-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-story-js": () => import("./../../../src/templates/story.js" /* webpackChunkName: "component---src-templates-story-js" */),
  "component---src-templates-video-js": () => import("./../../../src/templates/video.js" /* webpackChunkName: "component---src-templates-video-js" */),
  "component---src-templates-videos-category-js": () => import("./../../../src/templates/videos-category.js" /* webpackChunkName: "component---src-templates-videos-category-js" */),
  "component---src-templates-videos-tag-js": () => import("./../../../src/templates/videos-tag.js" /* webpackChunkName: "component---src-templates-videos-tag-js" */)
}

