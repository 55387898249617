import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { ListHoverItem } from '../';

import { menu } from '../../../data';
import { usePrismicPage } from '../../../hooks';

const PageMenu = ({ links }) => {
  return (
    <div className="mb-12">
      <div className="prose prose-custom">
        <h3 className="text-lg mb-8">In this Page:</h3>
      </div>
      <div className="divide-y divide-color-base divide-opacity-20 border-y border-color-base border-opacity-20 sm:mr-12 2xl:mr-24">
        {links.map((item, i) => {
          return (
            <Link
              key={`pagemenu-${i}`}
              to={`${item.path}/`}
              className="hover-row block overflow-hidden py-2 no-underline text-sm xs:text-[1.75rem]"
            >
              <ListHoverItem>
                {item.label}
              </ListHoverItem>
            </Link>
          )
        })}
      </div>
    </div>
  )
};

export default PageMenu;
