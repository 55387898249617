import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

const ViewLogos = (props) => {
  const { data } = props;
  const attrs = {
    className: props.className,
  };
  return (
    <div className="grid grid-cols-2 gap-4 sm:grid-cols-4 lg:grid-cols-6 2xl:grid-cols-8">
      {data.edges.map(({ node }, i) => (
        <Logo key={`logo-${node.id}`} node={node}{...attrs} />
      ))}
    </div>
  )
};

const ViewLogosAlt = (props) => {
  const { data } = props;
  const attrs = {
    className: props.className,
  };
  return (
    <div className="grid grid-cols-2 gap-4 sm:grid-cols-4 lg:grid-cols-4">
      {data.edges.map(({ node }, i) => (
        <Logo key={`logo-${node.id}`} node={node}{...attrs} />
      ))}
    </div>
  )
};

const Logo = ({ node, className }) => {
  return (
    <>
      {node.data.logo
        && (
        <>
          {node.data.website?.url
            ? (
            <a
              href={node.data.website.url}
              target="_blank"
              className={`block flex items-center no-underline px-8 py-20 ${ node.data.type === 'investor/owner' ? 'bg-off-white' : '' } ${className || ''}`}
              key={`investor-logo-${node.id}`}
              rel="nofollow noreferrer"
            >
              <div className="">
                <GatsbyImage
                  image={node.data.logo.gatsbyImageData}
                  alt={`${node.data.title?.text || "Company"} website`}
                  objectFit="contain"
                />
              </div>
            </a>
          ) : (
            <div className="block flex items-center bg-off-white px-8 py-20">
              <div className="">
                <GatsbyImage
                  image={node.data.logo.gatsbyImageData}
                  alt={`${node.data.title?.text || "Company"} logo`}
                  objectFit="contain"
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export {
  ViewLogos,
  ViewLogosAlt
};
