import React from 'react';
import PropTypes from 'prop-types';
import { Link, StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Cluster } from '../EveryLayout';
import { Section } from '../Layout';
import {
  Button,
  LinkButton,
  SocialShareWrapper,
  SocialShareFacebook,
  SocialShareLinkedIn,
  SocialShareTwitter,
  SocialShareYouTube,
} from './';
import { NewsletterSignup } from '../Forms';

import { socialPages } from '../../data';

/*
  factSheetPage query below is for later when page structure is handled by
  Prismic.
*/
const FooterCTA = () => (
  <StaticQuery
    query={graphql`
      {
        image: file(name: {eq: "Abler Nordic Kenya-26_16x9"}) {
          childImageSharp {
            gatsbyImageData(width: 1920, aspectRatio: 1.778)
          }
        }
        faceSheetPage: prismicPage(
          prismicId: {
            eq: "X8UTfxYAACsAp1rb"
          }
        ) {
          uid
        }
      }
    `}
    render={({ image, faceSheetPage }) => {
      return (
        <section className="bg-off-white">
          <div className="max-w-9xl mx-auto">
            <div className="relative">
              <div className="flex flex-col gap-8 justify-between z-10 bg-off-white p-4 py-8 md:p-8 md:pr-16 lg:absolute lg:top-0 lg:left-0 lg:w-1/2 2xl:w-1/3 lg:m-4">
                <div>
                  {/* 32px > 56px, lh 36px > 60px */}
                  <p className="prose prose-custom text-[2rem] sm:text-xl 2xl:text-[3.5rem] leading-[1.125] 2xl:leading-[1.075]">
                    Follow us on social media or subscribe to our newsletter for news and&nbsp;updates.
                  </p>
                  <Cluster className="md:mx-auto gap-2 my-5 2xl:my-8">
                    {socialPages.map((item, i) => (
                      <React.Fragment
                        key={`socialPages-${i}`}
                      >
                        <a
                          href={item.url}
                          rel="nofollow noreferrer"
                          className="no-underline block"
                          aria-label={item.name}
                        >
                          {item.name === 'LinkedIn' && (
                            <SocialShareLinkedIn className="rounded-sm h-11 w-11 2xl:h-12 2xl:w-12" aria-hidden="true" />
                          )}
                          {item.name === 'Facebook' && (
                            <SocialShareFacebook className="rounded-sm h-11 w-11 2xl:h-12 2xl:w-12" aria-hidden="true" />
                          )}
                          {item.name === 'X (Twitter)' && (
                            <SocialShareTwitter className="rounded-sm h-11 w-11 2xl:h-12 2xl:w-12" aria-hidden="true" />
                          )}
                          {item.name === 'YouTube' && (
                            <SocialShareYouTube className="rounded-sm h-11 w-11 2xl:h-12 2xl:w-12" aria-hidden="true" />
                          )}
                        </a>
                      </React.Fragment>
                    ))}
                  </Cluster>
                </div>
                <div>
                  {/*<span className="inline-block font-trade text-[1.3rem] leading-[1.2] mb-4">Newsletter</span>*/}
                  <NewsletterSignup>
                    <Button>Newsletter Subscribe</Button>
                  </NewsletterSignup>
                </div>
                {/*
                <Cluster>
                  <div>
                    <LinkButton path={`/about/fact-sheet/`}>
                      Download Fact Sheet
                    </LinkButton>
                  </div>
                  <div>
                    <span className="inline-block font-trade text-[1.3rem] leading-[1.2] mb-4">Newsletter</span>
                    <NewsletterSignup />
                  </div>
                </Cluster>
                */}
              </div>
              <GatsbyImage
                style={{
                  gridArea: "1/1",
                }}
                layout="fullWidth"
                alt=""
                image={
                  image.childImageSharp.gatsbyImageData
                }
                className="z-0"
              />
            </div>
          </div>
        </section>
      );
    }}
  />
);

export default FooterCTA;