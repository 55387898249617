import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Transition } from '@headlessui/react';

import {
  Cluster,
  Stack,
  Switcher
} from '../../EveryLayout';
import { ListHoverItem } from '../';

import { headerClean } from '../../../helpers';

// https://www.erichowey.dev/writing/load-more-button-and-infinite-scroll-in-gatsby/

const ViewNews = ({ articles }) => {

  // State for the list
  const [list, setList] = useState([...articles.edges.slice(0, 10)])

  // State to trigger load more
  const [loadMore, setLoadMore] = useState(false)

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(articles.edges.length > 10)

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true);
  }

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < articles.edges.length
      const nextResults = isMore
        ? articles.edges.slice(currentLength, currentLength + 10)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore]) //eslint-disable-line

  //Check if there is more
  useEffect(() => {
    const isMore = list.length < articles.edges.length
    setHasMore(isMore)
  }, [list]) //eslint-disable-line

  return (
    <div>
      <Stack>
        <div aria-hidden="true" className="hidden md:grid grid-cols-12">
          <div className="col-span-3 md:col-span-4 xl:col-span-3 2xl:col-span-2">
           {/* ? > 40px, lh ? > 46px */}
           <span className="text-lg 2xl:text-[2.5rem] leading-[1.15]">Date</span>
          </div>
          {/*
          <div className="col-span-3 md:col-span-2 xl:col-span-2">
            <span className="text-lg 2xl:text-[2.5rem] leading-[1.15]">Type</span>
          </div>
          */}
          <div className="col-span-6 xl:col-span-7 2xl:col-span-8">
            <span className="text-lg 2xl:text-[2.5rem] leading-[1.15]">Headline</span>
          </div>
        </div>
        <ul className="w-full divide-y divide-color-base divide-opacity-20 border-y border-color-base border-opacity-20">
          {list.map(({ node }, i) => (
            <Transition
              as="li"
              key={`news-listing-${i}`}
              show={true}
              appear={true}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 "
              enterTo="opacity-100"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="opacity-0"
            >
              <ListRow node={node} />
            </Transition>
          ))}
        </ul>
        <div>
          {/* ? > 21px, lh ? > 25.84px */}
          {hasMore ? (
            <button
              onClick={handleLoadMore}
              className="text-[1.3rem] italic link no-underline"
            >
              + More
            </button>
          ) : (
            <p className="prose prose-custom text-sm"><em>No more results.</em></p>
          )}
        </div>
      </Stack>
    </div>
  );
};

const ListRow = ({ node }) => {
  const headline = headerClean(node.data.title.text);
  const publishedDate = node.data.date || node.first_publication_date;
  return (
    <Link
      to={`/news/${node.uid}/`}
      className="px-2 block hover-row no-underline hover:bg-off-white"
      aria-label={`${node.data.title.text} (${node.data.category}) ${publishedDate}`}
    >
      <div className="grid grid-cols-4 md:grid-cols-12 py-1">
        <div className="h-full flex items-center col-span-3 md:col-span-4 xl:col-span-3 2xl:col-span-2 pb-2 md:pb-0">
          <ListHoverItem>
            {/* ? > 18px, lh ? > 22px */}
            <time className="font-trade text-[1rem] sm:text-xs 2xl:text-[1.15rem]">
              {publishedDate}
            </time>
          </ListHoverItem>
        </div>
        {node.data.category && (
          <div className="h-full flex justify-end md:justify-start items-center col-span-1 md:col-span-2 xl:col-span-2 pb-2 md:pb-0">
            <span className="font-trade text-[1rem] sm:text-xs 2xl:text-[1.15rem] md:min-w-[10rem]" aria-hidden="true">
              {node.data.category || 'News'}
            </span>
          </div>
        )}
        <div className="col-span-4 md:col-span-6 xl:col-span-7 2xl:col-span-8 pb-6 md:pb-0">
          {/* ? > 18px, lh ? > 22px */}
          <span
            className="text-[1rem] sm:text-xs 2xl:text-[1.15rem]s"
            aria-hidden="true"
            dangerouslySetInnerHTML={{ __html: headline }}
          />
        </div>
      </div>
    </Link>
  )
};

ViewNews.propTypes = {
  articles: PropTypes.shape({}).isRequired,
};

ListRow.propTypes = {
  node: PropTypes.shape({}).isRequired,
};

export default ViewNews;
