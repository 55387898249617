exports.linkResolver = (doc) => {
  if (doc.type === 'article') {
    return `/news/${doc.uid}/`;
  }
  if (doc.type === 'story') {
    return `/impact/meet-our-clients/${doc.uid}/`;
  }
  if (doc.type === 'person') {
    return '/about/team/';
  }
  if (doc.type === 'company') {
    if (doc.data.type === 'investee') {
      return '/partners/investees/';
    }
    return '/partners/investors/';
  }
  if (doc.type === 'page') {
    switch (doc.id) {
      case 'X8UTfxYAACsAp1rb':
        return '/about/fact-sheet/';
      case 'X6_QZhIAACYAP_Pj':
        return '/about/investment-criteria/';
      case 'Yby03BEAACEA1ge5':
        return '/about/sustainable-finance-disclosures/';
      case 'X8TXqxYAACsApk5k':
        return '/about/team/';
      case 'X8T73hYAACsApvCt':
        return '/partners/';
      case 'X8T9MRYAACoApvaT':
        return '/partners/investees/';
      case 'X8T-_RYAACoApv6b':
        return '/partners/investors/';
      case 'X8T3XxYAACoAptyT':
        return '/in-the-media/';
      case 'X8UFHxYAACwApxnb':
        return '/impact/';
      case 'X8UGjBYAACwApyBO':
        return '/impact/meet-our-clients/';
      case 'X8UF_BYAAC0Apx25':
        return '/impact/social-reports/';
      default:
        return '';
    }
  }
};
