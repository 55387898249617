import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import { Switcher } from '../../EveryLayout';

import { menu } from '../../../data';

const ContentPage = ({ section, title, page, image }) => {
  let sectionPage = [];
  if (section) {
    sectionPage = menu.filter(item => item.prismicId === section.prismicId);
  }

  return (
    <section className="pb-16">
      <div className="md:px-6 pt-6">
        <div className="max-w-9xl mx-auto">
          <div className="">
            <Switcher className="gap-4 mb-16">
              {image?.childImageSharp
                && (
                <figure className="max-w-[800px] mx-auto">
                  <GatsbyImage
                    image={image.childImageSharp.gatsbyImageData}
                    alt={image.image?.alt ? item.image.alt : ''}
                    objectFit="cover"
                  />
                </figure>
              )}
              <div className="bg-nordic-blue-500">
                <div className="prose prose-custom mx-6 mt-8 mb-12 xl:mx-12 xl:mt-12 xl:mb-16">
                  {/*
                  {(section.data?.title?.text && sectionPage.length > 0)
                    && (
                      <nav className="mb-4">
                        <Link
                          to={sectionPage[0].path}
                          className="font-trade text-[1rem] sm:text-xs 2xl:text-[1.3rem] leading-[1.4] 2xl:leading-[1.2]"
                        >
                          {section.data.title.text}
                        </Link>
                    </nav>
                  )}
                  */}
                  {title
                    && (
                      <h2 className="mt-0 mb-6">{title}</h2>
                  )}
                  {page.data?.subtitle
                    && (
                    <p className={`text-lg`}>{page.data.subtitle}</p>
                  )}
                </div>
              </div>
            </Switcher>
          </div>
        </div>
      </div>
      <div className="max-w-9xl mx-auto">


        <>
          {page.data.body1.map((slice, i) => {
            
            // Safeguard against slice types that we aren't querying for.
            if (!slice.id) return null;

            // Convert id to class.
            const id = `slice-${slice.id.toString().substring(0,5)}-${i}`;
            return (
              <div
                key={slice.id.toString()}
                id={id}
              >
                {(() => {
                  switch (slice.slice_type) {
                    case 'body':
                      return (
                        <div className="max-w-8xl pb-12 px-6">
                          <div className="flex flex-wrap">
                            <div className="grow" />
                            <div className="2xl:w-[53%]">
                              <div
                                className="prose prose-custom"
                                dangerouslySetInnerHTML={{ __html: slice.primary.body.html }}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    case 'text':
                      return (
                        <div>
                          {slice.items && slice.items.map((repeatable, j) => {
                            return (
                              <div
                                key={`${slice.id.toString()}-${j}`}
                                className="max-w-8xl pb-12 px-6"
                              >
                                <div className="flex flex-wrap">
                                  <div className="grow" />
                                  <div className="2xl:w-[53%]">
                                    <div
                                      className="prose prose-custom"
                                      dangerouslySetInnerHTML={{ __html: repeatable.section_rich_text.html }}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      );
                    default:
                      return '';
                  }
                })()}
              </div>
            )
          })}
        </>


      </div>
    </section>
  )
};

export default ContentPage;
